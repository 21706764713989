import ApiInstance from './ApiInstance.js'

export default {
  getInstituteCampuses() {
    return ApiInstance({ requiresAuth: true }).get('/campuses');
  },
  addNewCampuses(Data) {
    return ApiInstance({ requiresAuth: true }).post('/campuses', {campuses: Data});
  },
  editCampus(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/campuses/${id}`, Data[0]);
  },
  deleteCampus(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/campuses/${id}`);
  }
}
